import React from 'react';
import './App.css';
import { faTwitterSquare, faGithub, faFonticons, faFontAwesome } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function App() {
  return (
    <div className="App">
      <div id="navbar">
        <ul class="list">
        <li class="section"><a href="#welcome">Welcome</a></li>
        <li class="section"><a href="#projects">Projects</a></li>
        <li class="section"><a href="#learning">Currently Learning</a></li>
        <li class="section"><a href="#contact">Contact Me</a></li>
        </ul>

        <ul class="list2">
          <li class="section2"><a href="https://twitter.com/rjjon13" target="_target"><FontAwesomeIcon class="top" icon={faTwitterSquare}/></a></li>
          <li class="section2"><a href="https://github.com/RjDeleon1" target="_blank"><FontAwesomeIcon  class="top"icon={faGithub}/></a></li>
        </ul>
      </div>
      <div id="welcome">
        <h1>Hi, I'm Richard Deleon!</h1>
        <h3>I'm a Web Developer!</h3>
      </div>
      <div id="projects">
        <h1>This is what I've worked on!</h1>
        <div class="proj">
          <div class="project-tile">
          <a href="https://codepen.io/rjjon13/full/VJgddY" target="_blank">
            <img src={require('./TributePage.png')} class="proj-img" alt="Link to Tribute Page Project " />
          </a>
          </div>
          <div class="project-tile">
          <a href="https://codepen.io/rjjon13/full/pojBLjx" target="_blank">
            <img src={require('./JSDoc.png')} class="proj-img" alt="Link to Technical Documentation Project " />
          </a>
          </div>
          <div class="project-tile2">
          <a  href="https://codepen.io/rjjon13/full/KKVBJYG" target="_blank">
            <img  src={require('./RandomQ.png')} class="proj-img" alt="Link to Random Quote Machine Project " />
          </a>
          </div>
        </div>
      </div>
      <div id="learning">
        <h1>What am I currently learning?</h1>
        <h2>Codecademy</h2>
        <p class="description">I am currently enrolled in the computer science path on Codecademy, this path includes python as a programming language and an in-depth overview of data strcutures and algorithms.</p>
        <p class="description">All completed CodeCademy projects are available on my personal Github.</p>
        <img class="learn-img2" src={require('./Codecademy.png')} />

        {" "}

        <h2>freeCodeCamp</h2>
        <p class="description">I am also completing the freeCodeCamp curriculum, I have completed and obtained the following certifications:</p>
        <ul class="description2">
          <li>Respnsive Web Design</li>
          <li>Javascript Algorithms and Data Structures</li>
          <li>Front End Libraries</li>
        </ul>
        <img class="learn-img" src={require('./FCC.png')}/>
      </div>
      <div id="contact">
        <h1>Here's where you can catch me!</h1>
        <div >
        <a href="https://twitter.com/rjjon13" target="_blank">
          <FontAwesomeIcon class="Icons"  icon={faTwitterSquare}/>
        </a>
        <a href="https://github.com/RjDeleon1" target="_blank">
          <FontAwesomeIcon class="Icons"  icon={faGithub}/>
        </a>
        <p>Built by Richard Deleon-Romero</p>
        <p>Built: July 2020</p>
        </div>

      </div>
    </div>
  );
}

export default App;
